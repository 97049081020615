import { createStore } from 'vuex'
import Seo from "@/store/Seo"
import Project from "@/store/Project";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Seo:Seo,
    Project: Project
  }
})
