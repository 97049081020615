export default {
    hello:"Hello I'm",
    intro:"A Fullstack Web Developer",
    about:{
        title:"About Me",
        description:"I merge a passion for usability and user experience with technical knowledge to create cool digital experiences. My repertoire includes programming languages and tools such as Vue, Laravel, PHP, MySQL and Apache server configuration, HTML, CSS, Bootstrap, jQuery, and more.", // todo: write a better one
    },
    qualification:{
        title:"Qualification",
        mba:{
            title:"Master of Business Administration",
            year:"2017 - 2019",
            org:"Dr. C. V. Raman University, Kota",
            description:"Master in Human Resource Management & Production and Operation Management" // todo: add project and more description
        },
        be:{
            title:"Bachelor of Engineering",
            year:"2013 - 2017",
            org:"Chouksey Engineering College, Bilaspur",
            description:"Graduated (Honours) in Mechanical Engineering" // todo: add project and more description
        },
        //todo add 10th 12th
    },
    experience:{
        title:"Experience",

        ivy:{
            title:"Ivy point solution ltd.",
            year:"Aug 2021 - Present",
            position:"Sr. Full Stack Developer",
            description:[
                "Primary responsibilities includes, but are not limited to, completion of development and unit testing in every sprint, and ensure proper delivery by team.",
                "Currently leading a team of 5 developers",
                "Awarded <b>Employee of month</b> for Nov 2021."
            ]
        },

        opal:{
            title:"77Mile.com",
            year:"Nov 2020 - Aug 2021",
            position:"Full Stack Web Developer",
            description:[
                "Pulled from PHP, SQL, JavaScript and other backend library knowledge to bolster programming resources from existing website which increase visits by 58%.",
                "Done Technical and Off-page SEO of whole website",
                "Developed company portfolio website (opal groups and realtors)",
                "Used Java technology to build official android application. and deploy to play store",
                "Using Vue.js, Java and Laravel to develop single page mobile web application.",
            ]
        },

        wizard:{
            title:"Wizard Solutions",
            year:"Sept 2019 - Nov 2020",
            position:"Web Developer",
            description:[
                "Design & develop more than 10 Dynamic websites.",
                "Create more than 30 mockups for clients.",
                "Developed android web-application for clients which increases client base.",
                "Engineered backend systems which are easy to maintain and also support shared hosting.",
            ]
        },

        aisect:{
            title:"AISECT Education Centre, Bilaspur",
            year:"Aug 2017 - Sept 2019",
            position:"Faculty Member",
            description:[
                "Conducted engaging in-class discussions to facilitate learning and encourage participation.",
            ]
        },

    },
    projects:{
        unload:{
            title:"Unload",
            tags:["E-Commerce","Android Web Application",],
            description:"Android based application suit with two sub apps namely, Unload seller and Unload buyer. The app basically register building raw material supplier and allows customers to view products, choose, place and track order with a single touch. The backend of app is control by a web based control panel.",
        },
        bla:{
            title:"Bilaspur Law Academy",
            tags:["Educational Website"],
            description:"Educational website providing latest mock test, recorded videos and live chat for judicial exams.",
        },
        wizard:{
            title:"Wizard Online",
            tags:["Education Website",],
            description:"Customised online platform for students, where they can register/ join a course, access syllabus, assignments, question banks and many more contents. Student can also access video library and can appear in mock test.",
        },
        opal:{
            title:"77Mile",
            tags:["Real Estate Yellow Page Website",],
            description:"Online platform for classified real estate properties without any brokerage.",
        },
        majhalagna:{
            title:"Majhalagna",
            tags:["Matrimonial Website",],
            description:"Regional matrimonial website for maratha clan.",
        },
        bunnyj:{
            title:"Bunny-j",
            tags:["Food Delivery Website","Android Web Application",],
            description:"E-commerce website for homemade food and special cakes.",
        },
        oec:{
            title:"OEC.NET",
            tags:["Multi Vendor Educational Website",],
            description:"A common platform for educator and students.",
        },
        myoffice:{
            title:"My Office",
            tags:["Online Office Management Software",],
            description:"For managing student, staff and other records.",
        },
        myExam:{
            title:"My Exam",
            tags:["Online Assessment Software",],
            description:"Real time online assessment system.",
        },
        portfolio:{
            title:"Portfolios and Progressive Web Application",
            tags:["Portfolios",],
            description:"Designed and developed multiple portfolio website for Opal groups, Opal Realtors, Opal jobs as well as for me. Also developed PWA application for 77Mile, Wizard online, MTech4You, Dabang Bastar, and many more",
        }
    }
}