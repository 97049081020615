// ::::::: VUE :::::::
import { createApp } from 'vue'
import App from './App.vue'
const application = createApp(App)

// PWA APPLICATION
import './registerServiceWorker'

// ROUTER
import router from './router'
application.use(router)

// VUEX
import store from './store'
application.use(store)

// COOKIE
import cookie from './plugins/cookie'
application.use(cookie)

// AXIOS
import axios from "axios";
import VueAxios from "vue-axios";
application.use(VueAxios, axios)

// i18N
import i18N from "./plugins/i18n/main";
application.use(i18N)

// PARTICLES
import VueParticles from 'vue-particles'
application.use(VueParticles)

// FONTAWESOME
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all"

// BOOTSTRAP
import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap.css"


// MOUNTING APPLICATION
application.mount('#app')
