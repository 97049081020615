let defaultValue = {
    title:"Sanjeet Dutt - Full Stack Web developer",
    description:"Hi Welcome to my portfolio website.",
    canonical:null,
    favicon:process.env.VUE_APP_HOST+"/img/logo.svg"
}



const state= () => {
    return{
        title:defaultValue.title,
        description:defaultValue.description,
        canonical:defaultValue.canonical,
        favicon:defaultValue.favicon
    }
}

const getters = {
}

const actions = {
    changePageMeta(context, {title, description, canonical, favicon}){

        context.commit("changeTitle", title)
        context.commit("changeDescription", description)
        context.commit("changeCanonical", canonical)
        context.commit("changeFavIcon", favicon)

    }
}


const mutations = {
    changeTitle(state, payload){
        state.title = payload ? payload : defaultValue.title
        document.title = state.title
    },

    changeDescription(state, payload){
        state.description = payload ? payload : defaultValue.description

        let meta = document.querySelector("meta[name~='description']")

        if(!meta){
            meta = document.createElement("meta")
            meta.setAttribute("name","description")
            document.getElementsByTagName("head")[0].appendChild(meta)
        }

        meta.content = state.description
    },

    changeCanonical(state, payload){
        state.canonical = payload ? payload : defaultValue.canonical

        if(state.canonical === null)
            return;

        let link = document.querySelector("link[rel~='canonical']")

        if(!link){
            link = document.createElement("link")
            link.setAttribute("rel","canonical")
            document.getElementsByTagName("head")[0].appendChild(link)
        }

        link.setAttribute("href",state.canonical)
    },

    changeFavIcon(state, payload){
        state.favicon = payload ? payload : defaultValue.favicon

        let favicon = document.querySelector("link[rel~='icon']")
        if(!favicon){
            favicon = document.createElement("link")
            favicon.rel = "icon"
            document.getElementsByTagName("head")[0].appendChild(favicon)
        }
        favicon.href = state.favicon

    }
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}