import english from "@/plugins/i18n/english/_main"
// import hindi from "@/plugins/i18n/hindi/_main"

export const languages = {
    en: english,
    // hn: hindi,
}

let setting = {
    locale: "en",
    fallback: "en"
}

export default (app) =>{
    app.config.globalProperties.$t = (path) =>  translate(path, setting.locale, languages)
    app.config.globalProperties.$te = (val) =>  translateExists(setting.locale + "." + val, languages)
    app.config.globalProperties.$i18nLocal = setLocale
}

function translate(path, locale, language){
    const translate = translateExists(locale + "." +path, language)

    if(translate === false){

        if(locale !== setting.fallback)
            return translate(path, setting.fallback, language)

        console.error("Translation not found for " + path)
        return path
    }

    return translate
}
function translateExists(path, language){

    const i = path.indexOf(".")

    if(i > 0){
        const key = path.slice(0, i);
        if(!language[key])
            return false
        return translateExists (path.slice(i+1), language[key])
    }

    if(language[path])
        return language[path]

    return false
}

function setLocale(locale){
    if(!Object.keys(languages).includes(locale)){
        console.error(locale + " not found.")
        return false
    }
    setting.locale = locale
    return true
}