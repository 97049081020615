<template>
    <router-view></router-view>
</template>



<script>
import {mapActions} from "vuex"
export default {
    methods:{
        ...mapActions("Seo",["changePageMeta"])
    },
    mounted() {
        this.changePageMeta({})
    }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
</style>
