function imageObject (cssClass, imageSource, previewSource, alternateText = "Sanjeet Dutt", description=""){
    return{
        class: cssClass + " p-3 col-12 bg-white",
        src: imageSource,
        previewSource: previewSource,
        alt: alternateText,
        description: description
    }
}

const UNLOAD = [
    imageObject("col-md-3",require("@/assets/project/unload/1.png"),require("@/assets/project/unload/1.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/2.png"),require("@/assets/project/unload/2.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/3.png"),require("@/assets/project/unload/3.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/4.png"),require("@/assets/project/unload/4.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/5.png"),require("@/assets/project/unload/5.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/6.png"),require("@/assets/project/unload/6.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/7.png"),require("@/assets/project/unload/7.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/8.png"),require("@/assets/project/unload/8.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/9.png"),require("@/assets/project/unload/9.webp"),"Unload",""),
    imageObject("col-md-3",require("@/assets/project/unload/10.png"),require("@/assets/project/unload/10.webp"),"Unload",""),
]

const BLA = [
    imageObject("col-md-8",require("@/assets/project/bla/1.png"),require("@/assets/project/bla/1.webp"),"Bilaspur Law Academy",""),
    imageObject("col-md-4",require("@/assets/project/bla/2.png"),require("@/assets/project/bla/2.webp"),"Bilaspur Law Academy",""),
    imageObject("col-md-4",require("@/assets/project/bla/3.png"),require("@/assets/project/bla/3.webp"),"Bilaspur Law Academy",""),
    imageObject("col-md-8",require("@/assets/project/bla/4.png"),require("@/assets/project/bla/4.webp"),"Bilaspur Law Academy",""),
]
const WIZARD = [
    imageObject("col-md-8 p-3",require("@/assets/project/wizard/1.png"),require("@/assets/project/wizard/1.webp"),"Wizard Online",""),
    imageObject("col-md-4 p-3",require("@/assets/project/wizard/2.png"),require("@/assets/project/wizard/2.webp"),"Wizard Online",""),
    imageObject("col-md-4 p-3",require("@/assets/project/wizard/3.png"),require("@/assets/project/wizard/3.webp"),"Wizard Online",""),
    imageObject("col-md-8 p-3",require("@/assets/project/wizard/4.png"),require("@/assets/project/wizard/4.webp"),"Wizard Online",""),
    imageObject("col-md-6 p-3",require("@/assets/project/wizard/5.png"),require("@/assets/project/wizard/5.webp"),"Wizard Online",""),
    imageObject("col-md-6 p-3",require("@/assets/project/wizard/6.png"),require("@/assets/project/wizard/6.webp"),"Wizard Online",""),
    imageObject("col-md-6 p-3",require("@/assets/project/wizard/7.png"),require("@/assets/project/wizard/7.webp"),"Wizard Online",""),
    imageObject("col-md-6 p-3",require("@/assets/project/wizard/8.png"),require("@/assets/project/wizard/8.webp"),"Wizard Online",""),
    imageObject("col-md-6 p-3",require("@/assets/project/wizard/9.png"),require("@/assets/project/wizard/9.webp"),"Wizard Online",""),
]
const OPAL = [
    imageObject("col-md-12",require("@/assets/project/77mile/1.png"),require("@/assets/project/77mile/1.webp"),"77MILE",""),
    imageObject("col-md-6",require("@/assets/project/77mile/2.png"),require("@/assets/project/77mile/2.webp"),"77MILE",""),
    imageObject("col-md-6",require("@/assets/project/77mile/3.png"),require("@/assets/project/77mile/3.webp"),"77MILE",""),
    imageObject("col-md-6",require("@/assets/project/77mile/4.png"),require("@/assets/project/77mile/4.webp"),"77MILE",""),
    imageObject("col-md-6",require("@/assets/project/77mile/5.png"),require("@/assets/project/77mile/5.webp"),"77MILE",""),
]
const MYOFFICE = [
    imageObject("col-md-12",require("@/assets/project/myoffice/1.png"),require("@/assets/project/myoffice/1.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/2.png"),require("@/assets/project/myoffice/2.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/3.png"),require("@/assets/project/myoffice/3.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/4.png"),require("@/assets/project/myoffice/4.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/5.png"),require("@/assets/project/myoffice/5.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/6.png"),require("@/assets/project/myoffice/6.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/7.png"),require("@/assets/project/myoffice/7.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/8.png"),require("@/assets/project/myoffice/8.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/9.png"),require("@/assets/project/myoffice/9.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/10.png"),require("@/assets/project/myoffice/10.webp"),"My Office",""),
    imageObject("col-md-6",require("@/assets/project/myoffice/11.png"),require("@/assets/project/myoffice/11.webp"),"My Office",""),
]
const MYEXAM = [
    imageObject("col-md-12",require("@/assets/project/myexam/1.png"),require("@/assets/project/myexam/1.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/2.png"),require("@/assets/project/myexam/2.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/3.png"),require("@/assets/project/myexam/3.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/4.png"),require("@/assets/project/myexam/4.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/5.png"),require("@/assets/project/myexam/5.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/6.png"),require("@/assets/project/myexam/6.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/7.png"),require("@/assets/project/myexam/7.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/8.png"),require("@/assets/project/myexam/8.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/9.png"),require("@/assets/project/myexam/9.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/10.png"),require("@/assets/project/myexam/10.webp"),"My Exam",""),
    imageObject("col-md-6",require("@/assets/project/myexam/11.png"),require("@/assets/project/myexam/11.webp"),"My Exam",""),
]
const MAJHALAGNA = [
    imageObject("col-md-12",require("@/assets/project/majhalagna/1.png"),require("@/assets/project/majhalagna/1.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/2.png"),require("@/assets/project/majhalagna/2.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/3.png"),require("@/assets/project/majhalagna/3.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/4.png"),require("@/assets/project/majhalagna/4.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/5.png"),require("@/assets/project/majhalagna/5.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/6.png"),require("@/assets/project/majhalagna/6.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/7.png"),require("@/assets/project/majhalagna/7.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/8.png"),require("@/assets/project/majhalagna/8.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/9.png"),require("@/assets/project/majhalagna/9.webp"),"Majhalagna",""),
    imageObject("col-md-6",require("@/assets/project/majhalagna/10.png"),require("@/assets/project/majhalagna/10.webp"),"Majhalagna",""),
]
const PORTFOLIO = [
    imageObject("col-md-6",require("@/assets/project/portfolio/1.png"),require("@/assets/project/portfolio/1.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/2.png"),require("@/assets/project/portfolio/2.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/3.png"),require("@/assets/project/portfolio/3.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/4.png"),require("@/assets/project/portfolio/4.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/5.png"),require("@/assets/project/portfolio/5.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/6.png"),require("@/assets/project/portfolio/6.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/7.png"),require("@/assets/project/portfolio/7.webp"),"Portfolio",""),
    imageObject("col-md-6",require("@/assets/project/portfolio/8.png"),require("@/assets/project/portfolio/8.webp"),"Portfolio",""),
]


const state = ()=>{
    return{
        UNLOAD, BLA, WIZARD, OPAL, MYOFFICE, MYEXAM, MAJHALAGNA, PORTFOLIO
    }
}

const getters = {
    getProjectDetails: (state)=>(projectName)=>{

            let prj = projectName.toString().toUpperCase().trim()

            if(state[prj])
                return state[prj]

            console.error("No project details found for " + prj)

            return []
    }
}

const actions = {}

const mutations = {}

export default {
    namespaced: true,
    state, getters, actions, mutations
}